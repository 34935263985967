/** 
Declare the type of action as constant.
WHY?
    - Help reduce typos 
    - Help reduce bugs and mistake
    - If you make typo and dispatch an undefined constants,
      the app will throw error to alert the mistake. 
*/

// Import
import { sha256 } from "js-sha256";
import store, { history } from '../store';

// Functions

// Action Types
const UPDATE_ID_TOKEN   = "UserManagementReducers/UPDATE_ID_TOKEN";
const UPDATE_USER_DETAIL= "UserManagementReducers/UPDATE_USER_DETAIL"
const REQ_LOGIN         = "UserManagementReducers/REQ_LOGIN";
const RCV_LOGIN         = "UserManagementReducers/RCV_LOGIN";

// Action Creators
/* 
 *  !LEGEND! 
 *      req = request
 *      rcv = receive
 *      fch = fetch
 *      snd = send
 */


// Actions


// You can put impure functions within your action creators

export const updateIdToken = (id_token) => dispatch => {  
    if ( id_token === null ) {
        localStorage.removeItem("id_token");
    }  else {
        localStorage.setItem("id_token", id_token);
    }

    dispatch({
        type: UPDATE_ID_TOKEN,
        payload: {
            id_token: id_token
        }
    })
}

export const updateUserDetail = (data) => ({
    type: UPDATE_USER_DETAIL,
    payload: {
        userDetail: data
    }
})

const reqLogin = data => ({
    type: REQ_LOGIN,
    payload: {
        status: "req_login",
        data: data
    }
});
  
export const rcvLogin = data => ({
    type: RCV_LOGIN,
    payload: {
        status: "rcv_login",
        data: data
    }
});


export const fetchRefreshToken = () => {
    return new Promise((resolve, reject) => {
        // Put outgoing requester here
        const body = {
            id_token: store.getState().UserManagementReducers.id_token
        };
    
        fetch(process.env.REACT_APP_SERVER_URL + `/app/console/${process.env.REACT_APP_SYSTEM_STATUS}/auth/refresh/`, {
            headers: {
                accept: "application/json",
                'Content-Type': 'application/json; charset=utf-8'
            },
            method: "POST",
            body: JSON.stringify(body)
        })
            .then(response => {
                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            })
            .then(result => {
            //   console.log(result);
    
                if ( result.status === "success" ) {
                    store.dispatch(updateIdToken(result.id_token));
                    
                    localStorage.setItem("keycloakToken", JSON.stringify(result.keycloakToken))
                    
                    resolve(result.id_token);
                } else {
                    throw(result.error);
                }
            
            })
            .catch(err => {
                store.dispatch(updateIdToken(null));

            //   console.log(err);
                reject(err.toString());
            });
    });  
};

export const fetchUpdateToken = (old_id_token, new_id_token) => dispatch => {
    return new Promise((resolve, reject) => {
        // Put outgoing requester here
        const body = {
            // old_id_token: old_id_token,
            old_id_token: "",
            new_id_token: new_id_token
        };
    
        fetch(process.env.REACT_APP_SERVER_URL + `/app/console/${process.env.REACT_APP_SYSTEM_STATUS}/auth/update/`, {
            headers: {
                accept: "application/json",
                'Content-Type': 'application/json; charset=utf-8'
            },
            method: "POST",
            body: JSON.stringify(body)
        })
            .then(response => {
                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            })
            .then(result => {
                //   console.log(result);
        
                if ( result.status === "success" ) {
                    const old_id_token = localStorage.getItem("id_token") !== null ? localStorage.getItem("id_token") : "";
                    const res_old_id_token = result.old_id_token;
                    const res_new_id_token = result.new_id_token;
                    
                    // console.log("old_id_token: "+old_id_token)
                    // console.log("res_old_id_token: "+res_old_id_token)

                    // if ( old_id_token === res_old_id_token ) {
                        dispatch(updateIdToken(res_new_id_token));
                    // }

                    resolve(result);

                } else {
                    // localStorage.removeItem("id_token");
                    // dispatch(updateIdToken(null));
        
                    throw(result.error);
                }
            
            })
            .catch(err => {
                reject(err.toString());
            });
    });  
};

export const fetchLogin = (email, password) => dispatch => {
    return new Promise((resolve, reject) => {
        // Put outgoing requester here  
        const body = {
            email: email,
            password: sha256(password),
        };

        dispatch(reqLogin(body));

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/"+process.env.REACT_APP_SYSTEM_STATUS+"/user/login/", {
                headers: {
                    "Content-Type"  : 'application/json'
                },
                method: "POST",
                body: JSON.stringify(body)
            }).then(response => {
                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            }).then(result => {
                if ( result.status === "success" ) {
                    const id_token = result.id_token;
                    
                    dispatch(rcvLogin(result));

                    dispatch(updateIdToken(id_token));

                    localStorage.setItem("keycloakToken", JSON.stringify(result.keycloakToken))

                    resolve(result);

                } else {
                    throw(result.error);
                }

            }).catch(err => {
                reject(err)
            });
    });
};

export const fetchLoginKeycloak = (email, password) => dispatch => {
    return new Promise((resolve, reject) => {
        // Put outgoing requester here  

        const body = {
            client_id : process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
            // username : "admin",
            // password : "password",
            username : email,
            password : password,
            grant_type : "password"
        }
        
        var formBody = [];
        for (var property in body) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(body[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        // dispatch(reqLogin(body));

        console.log(process.env.REACT_APP_KEYCLOAK_URL)
        fetch(process.env.REACT_APP_KEYCLOAK_URL + "/realms/"+process.env.REACT_APP_KEYCLOAK_REALM+"/protocol/openid-connect/token", {
            headers: {
                "Content-Type": 'application/x-www-form-urlencoded'
            },
            method: "POST",
            body: formBody
        }).then(response => {
            if ( response.ok ) {
                return response.json();
            } else {
                throw(response.status+" "+response.statusText)
            }
        }).then(result => {
                localStorage.setItem("keycloakToken", JSON.stringify(result))

                resolve(result);

        }).catch(err => {
            reject(err)
        });
    });
};

export const fetchRegister = (data) => dispatch => {
    return new Promise((resolve, reject) => {    
        const {
            email,
            username,
            firstname,
            lastname,
            password
        } = data;

        // Put outgoing requester here  
        const body = {
            email: email,
            username: username,
            firstname: firstname,
            lastname: lastname,
            password: password,
        };

        // dispatch(reqRegister(body));

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/"+process.env.REACT_APP_SYSTEM_STATUS+"/user/register/", {
                headers: {
                    "Content-Type"  : 'application/json'
                },
                method: "POST",
                body: JSON.stringify(body)
            }).then(response => {
                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            }).then(result => {
                if ( result.status === "success" ) {
                    const id_token = result.id_token;
                    
                    // dispatch(rcvRegister(result));
                    
                    // dispatch(updateIdToken(id_token));

                    // localStorage.setItem("keycloakToken", JSON.stringify(result.keycloakToken))

                    resolve(result);

                } else {
                    throw(result.error);
                }

            }).catch(err => {
                reject(err)
            });
    });
};

export const fetchUserChangeEmail = (data) => dispatch => {
    return new Promise((resolve, reject) => {    
        const id_token = store.getState().UserManagementReducers.id_token;

        const { old_email, new_email, password } = data;

        // Put outgoing requester here  
        const body = {
            id_token: id_token,
            old_email: old_email,
            new_email: new_email,
            password: password
        };

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/"+process.env.REACT_APP_SYSTEM_STATUS+"/user/email/change/", {
                headers: {
                    "Content-Type"  : 'application/json'
                },
                method: "POST",
                body: JSON.stringify(body)
            }).then(response => {
                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            }).then(result => {
                if ( result.status === "success" ) {
                    resolve(result);
                } else {
                    throw(result.error);
                }

            }).catch(err => {
                reject(err)
            });
    });
};

export const fetchEmailVerificationRequestLink = (data) => dispatch => {
    return new Promise((resolve, reject) => {    
        const id_token = store.getState().UserManagementReducers.id_token;

        const { id_user, email } = data;

        // Put outgoing requester here  
        const body = {
            id_token: id_token,
            id_user: id_user,
            email: email
        };

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/"+process.env.REACT_APP_SYSTEM_STATUS+"/email/verification/sendlink/", {
                headers: {
                    "Content-Type"  : 'application/json'
                },
                method: "POST",
                body: JSON.stringify(body)
            }).then(response => {
                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            }).then(result => {
                if ( result.status === "success" ) {
                    resolve(result);
                } else {
                    throw(result.error);
                }

            }).catch(err => {
                reject(err)
            });
    });
};

export const fetchResetPassword = (email) => dispatch => {
    return new Promise((resolve, reject) => {    
        // Put outgoing requester here  
        const body = {
            email: email
        };

        // dispatch(reqResetPassword(body));

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/"+process.env.REACT_APP_SYSTEM_STATUS+"/user/reset/", {
                headers: {
                    "Content-Type"  : 'application/json'
                },
                method: "POST",
                body: JSON.stringify(body)
            }).then(response => {
                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            }).then(result => {
                if ( result.status === "success" ) {                
                    // dispatch(rcvResetPassword(result));

                    resolve(result);

                } else {
                    throw(result.error);
                }

            }).catch(err => {
                reject(err)
            });
    });
};

export const fetchChangePassword = (id_token, password) => dispatch => {
    return new Promise((resolve, reject) => {
        // Put outgoing requester here  
        const body = {
            id_token: id_token,
            password: password
        };

        // dispatch(reqChangePassword(body));

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/"+process.env.REACT_APP_SYSTEM_STATUS+"/user/changepass/", {
                headers: {
                    "Content-Type"  : 'application/json'
                },
                method: "POST",
                body: JSON.stringify(body)
            }).then(response => {
                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            }).then(result => {
                if ( result.status === "success" ) {                
                    // dispatch(rcvChangePassword(result));

                    resolve(result);

                } else {
                    if ( result.error === "token expired" ) {
                    fetchRefreshToken()
                        .then((id_token) => {
                            dispatch(fetchChangePassword(id_token, password))
                        })
                        .catch((err) => {
                            history.push("/login");
                        });
                    } 
                    else {
                        throw(result.error)
                    }
                }

            }).catch(err => {
                reject(err)
            });
    });
};

export const fetchUserDetail = (id_token) => dispatch => {
    return new Promise((resolve, reject) => {
        // Put outgoing requester here  
        const body = {
            id_token: id_token
        };

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/"+process.env.REACT_APP_SYSTEM_STATUS+"/user/detail/", {
            headers: {
                "Content-Type"  : 'application/json'
            },
            method: "POST",
            body: JSON.stringify(body)
        }).then(response => {
            if ( response.ok ) {
                return response.json();
            } else {
                throw(response.status+" "+response.statusText)
            }
        }).then(result => {
            if ( result.status === "success" ) {   
                dispatch(updateUserDetail(result.data))     
                
                resolve(result);

            } else {
                if ( result.error === "token expired" ) {
                fetchRefreshToken()
                    .then((new_id_token) => {
                        dispatch(fetchUserDetail(new_id_token))
                            .then(()=>{})
                            .catch(()=>{});
                    })
                    .catch((err) => {
                        history.push("/login");
                    });
                } 
                else {
                    throw(result.error)
                }
            }

        }).catch(err => {
            reject(err)
        });
    });
};

export const fetchUserUpdate = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        const id_token = store.getState().UserManagementReducers.id_token;
        
        // Put outgoing requester here  
        const body = {
            ...data,
            id_token: id_token
        };

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/"+process.env.REACT_APP_SYSTEM_STATUS+"/user/update/", {
            headers: {
                "Content-Type"  : 'application/json'
            },
            method: "POST",
            body: JSON.stringify(body)
        }).then(response => {
            if ( response.ok ) {
                return response.json();
            } else {
                throw(response.status+" "+response.statusText)
            }
        }).then(result => {
            if ( result.status === "success" ) {   
                dispatch(fetchUserDetail(id_token))
                    .then(()=>{})
                    .catch(()=>{});
                
                resolve(result);

            } else {
                if ( result.error === "token expired" ) {
                    fetchRefreshToken()
                        .then((new_id_token) => {
                            dispatch(fetchUserUpdate(data))
                                .then(()=>{})
                                .catch(()=>{});
                        })
                        .catch((err) => {
                            history.push("/login");
                        });
                } 
                else {
                    throw(result.error)
                }
            }

        }).catch(err => {
            reject(err)
        });
    });
};

// Reducer's initial state
const initialState = {
    status   : "",
    data     : null,
    id_token : "",
    username : '',
    userDetail: null
};


// Reducers

// You must only write pure function when trying to build the reducer! 

export default function UserManagementReducers(state = initialState, action) {
    switch (action.type) {
        case UPDATE_ID_TOKEN:
            return {
                ...state,
                id_token: action.payload.id_token
            };
        case REQ_LOGIN:
            return {
                ...state,
                status: action.payload.status,
                data: action.payload.data
            };
        case RCV_LOGIN:
            return {
                ...state,
                status: action.payload.status,
                data: action.payload.data
            };
        case UPDATE_USER_DETAIL:
            return {
                ...state,
                userDetail: action.payload.userDetail
            };
        default:
            return state;
    }
}



// Side effects, only as applicable
// e.g. thunks,epics, etc